import request from '@index/utils/request'

export function handlePageQueryFormSignupTableJoinTeam(data) { //分页查询正式报名表(参赛队)
	return request({
		url: 'formsignuptable/pageQueryFormSignupTableJoinTeam',
		method: 'post',
		data
	})
}



export function handleDownload(matchId,con1) { //分页查询正式报名表(参赛队)
	return request({
		url: 'formsignuptable/download',
		method: 'post',
		responseType: 'blob',
		params: {
			matchId: matchId,
			con1: con1
		}
	})
}