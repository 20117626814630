import matchtitle from '../../components/matchtitle';
import {

	Message,
} from 'element-ui'
import {
	handlePageQueryFormSignupTableJoinTeam,
	handleDownload
} from '@index/api/joinmatch/formsignuptable';
export default {
	name: 'onlinesignuptable',
	components: {
		matchtitle
	},
	data() {
		return {
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			sumFee: 0,
			matchId: sessionStorage.getItem('joinMatchId'),
		};
	},
	watch: {

	},
	created() {
		this.pageQuery();
	},
	mounted() {

	},
	destroyed() {

	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQueryFormSignupTableJoinTeam({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con1: this.queryForm.con1,
				matchId: this.matchId,
				sortWay: [{
					fieldName: 'orderNo',
					sortWay: 'ASC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.sumFee = res.extendData.sumFee;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					})
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		download() {
			handleDownload(this.matchId, this.queryForm.con1).then(res => {
				var fileName = "正式报名表.xlsx";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值

					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {

					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});



		}

	}
}